.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Parallax {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  
  &_Group {
    position: relative;
    height: 100vh;
    transform-style: preserve-3d;
  }

  &_BG {
    position:fixed;
    top:0px;
    margin:auto;
    width:100%;
    min-height:400px;
    top: 0; left: 0;
    background-position: top center;
    background-repeat: no-repeat;
  }

  &_BG1 {
    background-image: url("./Images/Layer1.png");
    z-index: 1;
  }
  &_BG2 {
    background-image: url("./Images/Layer2.png");
    z-index: 2;
  }
  &_BG3 {
    background-image: url("./Images/Layer3.png");
    z-index: 3;
  }
  &_BG4 {
    background-image: url("./Images/Layer4.png");
    z-index: 5;
  }
  &_TXT {
    background-image: url("./Images/Layer5.png");
    z-index: 4;
  }
}

.bg {
  height: 120vh;
  min-height: 69vw;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center top;
  background-image: url("./Images/Personal_Website_BGonly.jpg");
}

.myname {
  height: 112vh;
  min-height: 60vw;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center top;
  background-image: url("./Images/Layer5.png");
}

.sectionheader {
  min-width: 40vh;
  font-size: 5vh;
  text-align: center;
  font-weight: bold;
  padding-top: 6vh;
  padding-bottom: 4vh;
  color: #f3c37b;
}

.icon {
  &black {
    width: calc(0.5vw + 3vh);
    margin-left: calc(0.3% + 0.5vh);
    margin-right: calc(0.3% + 0.5vh);
  }
  &sblack {
    top: 34%;
    left: 58%;
    width: 100%;
    // height: 100%;
    position: absolute;
    text-align: left;
  }
  &white {
    width: 4vh;
    margin-left: calc(1vh + 2vw);
    margin-right: calc(1vh + 2vw);
  }
  &swhite {
    width: 100%;
    text-align: center;
    padding-bottom: 10vh;
  }
}

.projects {
  display: flex; 
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 10vw;
  margin-right: 10vw;
}

.proj {
  &_container {
    position: relative;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    padding: 3vh 2vw 3vh 2vw;
    max-width: 960px;
    background-color: #111113;
    border-radius: 2%;
    border-color: #2e3144;
    border-width: 1vh;
    border-style: ridge;
  }
  &_name {
    font-size: 3vh;
    font-weight: bold;
    text-decoration: none;
    color: #ffd6ce;
  }
  &_tech {
    font-size: 2.2vh;
    font-weight: bold;
    font-style: italic;
    color: #b7daef;
  }
  &_desc {
    padding-top: 1vh;
    font-size: 2.1vh;
    color: #ececec;
    margin-bottom: 6vh;
  }
  &_icon {
    width: 3vh;
    margin-left: calc(1vh + 0.5vw);
    margin-right: calc(1vh + 0.5vw);
    &s {
      position: absolute;
      bottom: 3vh;
      right: 1vw;
    }
  }
}

.projectsline {
  margin-left: 12vw;
  margin-right: 12vw;
  height:2px;
  color:#3c4221;
  background-color:#402a50;
  border-width: 0;
  margin-top: 1vh;
  margin-bottom: 1vh;
}


.exp {
  &_container {
    padding-left: 8vw;
    padding-right: 8vw;
    display: flex; 
    flex-direction: row;
    padding-bottom: 10vh;
  }
  &_image {
    border-radius: 0%;
    height: 18vh;
    width: 18vh;
    object-fit: cover;
    margin-right: 5vw;
  }
  &_name {
    font-size: 3vh;
    font-weight: bold;
    color: #eee3cf;
  }
  &_position {
    font-size: 2.5vh;
    font-weight: bold;
    color: #efc0b7;
  }
  &_duration {
    font-size: 2.2vh;
    font-weight: bold;
    font-style: italic;
    color: #b7efef;
  }
  &_desc {
    padding-top: 1vh;
    font-size: 2vh;
    color: #dbdbdb;
    white-space: pre-line;
  }
}

.contact {
  &_email {
    font-size: 2.2vh;
    color: #dbdbdb;
    text-align: center;
    white-space: pre-line;
  }
}

.horizontalflex {
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.whitespace {
  padding: 15vh;
}